import { Alert, Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppSelector } from '../../../app/hooks';
import { defaultColorPalette } from '../../../compute/colormap';
import { defaultPlotlyArguments, getGroupedMap, getRecordNameWithBr, sortZippedArrays } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectColormap,
  selectColormapRef,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationMethod,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectRecords,
  selectSessionID,
  selectSubtractItemName,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AllIntensitySet, AryRecord, AuxiliarySensorPrettyNameMap, ComparisonMeasuresType, IntensityFigureType, IntensitySet, IntensitySource, IntensityType } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import FullscreenGraphicModal from '../FullscreenGraphicModal';

type IntensityFigureProps = {
  isVisibleModal: boolean;
  setIsVisibleModal: (value: React.SetStateAction<boolean>) => void;
  intensitySource: IntensitySource;
  intensityType: IntensityType;
  figureType: IntensityFigureType;
  qualityControlMode?: boolean;
};

const IntensityFigure: React.FC<IntensityFigureProps> = ({ isVisibleModal, setIsVisibleModal, intensitySource, intensityType, figureType, qualityControlMode }) => {
  const [allIntensitySet, setAllIntensitySet] = useState<AllIntensitySet | null>(null);

  const boundariesMap = useAppSelector(selectBoundariesMap);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const sessionID = useAppSelector(selectSessionID);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);
  const humidityCompensationMethod = useAppSelector(selectHumidityCompensationMethod);

  const cmap = useAppSelector(selectColormap);
  const cmapRef = useAppSelector(selectColormapRef);
  const records = useAppSelector(selectRecords);
  const [_cmap, setCmap] = useState<Record<string, string> | undefined>();

  const [effectiveRecords, setEffectiveRecords] = useState<AryRecord[]>([]);
  const [error, setError] = useState<string | undefined>();

  const { authState } = useOktaOrQueryAuth();

  const [groupedIntensities, setGroupedIntensities] = useState<Record<string, number[]>>();
  const [groupedEffectiveRecords, setGroupedEffectiveRecords] = useState<Record<string, AryRecord[]>>();

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (intensitySource === IntensitySource.Signature && intensityType !== IntensityType.Average) return;
    fetchAuthorizedAPIEndpoint(`/compute/intensities?session_id=${sessionID}&intensity_source=${intensitySource}&intensity_type=${intensityType}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractItemName,
        excludedSpots,
        excludedPeptides,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
          Method: humidityCompensationMethod,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((receivedIntensitySet: AllIntensitySet) => {
        setAllIntensitySet(receivedIntensitySet);
        if (records === undefined) return;

        let _effectiveRecords: AryRecord[] = [];
        records.forEach((r) => {
          if (!excludedRecordIDs.includes(r.ID)) {
            _effectiveRecords.push(r);
          }
        });
        setEffectiveRecords(_effectiveRecords);
        setError(undefined);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [
    authState,
    sessionID,
    boundariesMap,
    excludedRecordIDs,
    aggregatePeptides,
    subtractItemName,
    excludedSpots,
    excludedPeptides,
    intensityType,
    intensitySource,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    humidityCompensationMethod,
  ]);

  const getCurrentIntensitySet = useCallback(
    (_allIntensitySet: AllIntensitySet): IntensitySet | undefined => {
      let _actualIntensitySet = undefined;
      switch (intensitySource) {
        case +IntensitySource.Signature:
          _actualIntensitySet = _allIntensitySet.Signature;
          break;
        case +IntensitySource.Sensogram:
          _actualIntensitySet = _allIntensitySet.Sensogram;
          break;
        case +IntensitySource.Humidity:
          _actualIntensitySet = _allIntensitySet.Humidity;
          break;
        case +IntensitySource.SunriseCo2:
          _actualIntensitySet = _allIntensitySet.SunriseCo2;
          break;
        case +IntensitySource.ZephyrAirflow:
          _actualIntensitySet = _allIntensitySet.ZephyrAirflow;
          break;
        case +IntensitySource.PidVoc:
          _actualIntensitySet = _allIntensitySet.PidVoc;
          break;
        case +IntensitySource.Pms1:
          _actualIntensitySet = _allIntensitySet.Pms1;
          break;
        case +IntensitySource.Pms25:
          _actualIntensitySet = _allIntensitySet.Pms25;
          break;
        case +IntensitySource.Pms10:
          _actualIntensitySet = _allIntensitySet.Pms10;
          break;
        case +IntensitySource.BmeCo2:
          _actualIntensitySet = _allIntensitySet.BmeCo2;
          break;
        case +IntensitySource.BmeVoc:
          _actualIntensitySet = _allIntensitySet.BmeVoc;
          break;
      }
      return _actualIntensitySet;
    },
    [intensitySource]
  );

  const getIntensities = useCallback(
    (actualIntensitySet: IntensitySet) => {
      let _intensities: number[] = [];
      switch (intensityType) {
        case +IntensityType.Average:
          _intensities = actualIntensitySet.Averages;
          break;
        default:
          _intensities = actualIntensitySet.Averages;
          break;
      }
      return _intensities;
    },
    [intensityType]
  );

  const getHumidityIntensities = useCallback(
    (_intensities: number[]): number[] | undefined => {
      // Percents
      if (intensitySource === +IntensitySource.Humidity && _intensities !== null && _intensities.length !== 0) {
        return _intensities.map((e) => 100 * e);
      }
    },
    [intensitySource]
  );

  useEffect(() => {
    if (allIntensitySet === null) return;

    const actualIntensitySet = getCurrentIntensitySet(allIntensitySet);

    if (actualIntensitySet === undefined || actualIntensitySet.Labels === null) return;

    let currentIntensities: number[] = [];

    const _intensities = getIntensities(actualIntensitySet);
    currentIntensities = _intensities;

    const _humidityIntensities = getHumidityIntensities(currentIntensities);
    if (_humidityIntensities) currentIntensities = _humidityIntensities;

    if (currentIntensities === null || !actualIntensitySet || actualIntensitySet.Labels === null || !effectiveRecords) {
      setError('No data available');
      return;
    }

    setGroupedIntensities(getGroupedMap(actualIntensitySet.Labels, currentIntensities));
    setGroupedEffectiveRecords(getGroupedMap(actualIntensitySet.Labels, effectiveRecords));
  }, [allIntensitySet, effectiveRecords, getCurrentIntensitySet, getHumidityIntensities, getIntensities]);

  useEffect(() => {
    if (qualityControlMode && cmapRef !== undefined) {
      setCmap({ ...cmapRef[ComparisonMeasuresType.Reference], ...cmapRef[ComparisonMeasuresType.Test], ...cmapRef[ComparisonMeasuresType.Other] });
    } else if (cmap !== undefined) {
      setCmap(cmap);
    }
  }, [cmap, cmapRef, qualityControlMode]);

  var plotlyLayout: Partial<Plotly.Layout> = { ...defaultPlotlyArguments.layout };
  plotlyLayout.bargap = 0.15;
  plotlyLayout.bargroupgap = 0.1;
  plotlyLayout.barmode = 'group';
  plotlyLayout.xaxis = {
    automargin: true,
    type: 'category',
  };
  plotlyLayout.yaxis = {
    automargin: true,
    title: { text: `${AuxiliarySensorPrettyNameMap[+intensitySource].name}, ${AuxiliarySensorPrettyNameMap[+intensitySource].unit}` },
  };

  var plotlyData: Plotly.Data[] = [];

  var ticktextX: string[] = [];
  var tickvalsX: number[] = [];

  if (_cmap !== undefined && groupedEffectiveRecords && groupedIntensities) {
    if (figureType === +IntensityFigureType.Box) {
      Object.entries(groupedIntensities).forEach(([groupName, intensities]) => {
        console.log();
        plotlyData.push({
          name: groupName,
          type: 'box',
          hoverinfo: 'name',
          hoverlabel: { namelength: -1 },
          x: intensities.map((_) => groupName),
          y: intensities,
          marker: {
            color: qualityControlMode ? _cmap[groupName] : defaultColorPalette[_cmap[groupName]],
          },
        });
      });
    } else if (figureType === +IntensityFigureType.Bar) {
      var i = 0;
      Object.entries(groupedIntensities).forEach(([groupName, intensities]) => {
        let _effectiveRecords = groupedEffectiveRecords[groupName];

        let sortKeys = _effectiveRecords.map((r) => r.ID);
        let zippedIntensities = sortZippedArrays(sortKeys, intensities);
        let zippedEffectiveRecords = sortZippedArrays(sortKeys, _effectiveRecords);

        const sortedIntensities = zippedIntensities.map(({ value }) => value);
        const sortedEffectiveRecords = zippedEffectiveRecords.map(({ value }) => value);
        let names = sortedEffectiveRecords.map((r) => getRecordNameWithBr(r));

        ticktextX.push(groupName);
        tickvalsX.push(i + intensities.length / 2);

        plotlyData.push({
          name: groupName,
          type: 'bar',
          x: names,
          y: sortedIntensities,
          hoveron: 'points',
          hoverinfo: 'x+y',
          marker: {
            color: qualityControlMode ? _cmap[groupName] : defaultColorPalette[_cmap[groupName]],
          },
        });
        i += intensities.length;
      });
      plotlyLayout.xaxis.ticktext = ticktextX;
      plotlyLayout.xaxis.tickvals = tickvalsX;
      const min = Math.max(...Object.values(groupedIntensities).flat());
      const max = Math.min(...Object.values(groupedIntensities).flat());
      plotlyLayout.yaxis.range = [max, min];
    } else if (figureType === +IntensityFigureType.Temporal) {
      plotlyLayout.xaxis = {
        automargin: true,
        type: '-',
      };
      Object.entries(groupedIntensities).forEach(([groupName, intensities]) => {
        plotlyData.push({
          name: groupName,
          type: 'scatter',
          hoverlabel: { namelength: -1 },
          x: intensities.map((_, i) => new Date(1000 * groupedEffectiveRecords[groupName][i].AbsoluteTimestamp)),
          y: intensities,
          line: {
            color: qualityControlMode ? _cmap[groupName] : defaultColorPalette[_cmap[groupName]],
          },
        });
      });
    }
  }

  return (
    <>
      {error && error !== '' ? (
        <Alert type="error" message={error} style={{ marginTop: 10, borderRadius: 5, width: '100%' }} />
      ) : (
        <>
          <Plot divId="intensity_box_plot" debug={true} data={plotlyData} useResizeHandler={true} layout={plotlyLayout} config={defaultPlotlyArguments.config} style={defaultPlotlyArguments.style} />
          <FullscreenGraphicModal title="Intensity" visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
            <Plot divId="intensity_box_plot" debug={true} data={plotlyData} useResizeHandler={true} layout={plotlyLayout} config={defaultPlotlyArguments.config} style={{ ...defaultPlotlyArguments.style, width: undefined, aspectRatio: '4/3' }} />
          </FullscreenGraphicModal>
        </>
      )}
    </>
  );
};

export default IntensityFigure;
