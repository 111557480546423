import { Button, Typography } from 'antd';
import ModalComponent from '../../../components/Modal/ModalComponent';
import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { fetchAuthorizedAPIEndpoint } from '../../../utils';
import { useAppSelector } from '../../../app/hooks';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationMethod,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectInterpretation,
  selectSessionID,
  selectSubtractItemName,
  selectThresholdIntensity,
  selectThresholdSignature,
} from '../../../features/analysisConfig/analysisConfigSlice';

type SaveAnalysisConfigProps = {};

const SaveAnalysisConfig: React.FC<SaveAnalysisConfigProps> = () => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const { authState } = useOktaAuth();

  const subtractedItemName = useAppSelector(selectSubtractItemName);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);
  const humidityCompensationMethod = useAppSelector(selectHumidityCompensationMethod);

  const thresholdIntensity = useAppSelector(selectThresholdIntensity);
  const thresholdSignature = useAppSelector(selectThresholdSignature);
  const interpretation = useAppSelector(selectInterpretation);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);
  const sessionID = useAppSelector(selectSessionID);
  const [error, setError] = useState<string | undefined>();
  const [saveData, setSaveData] = useState<any>();

  const handleSave = () => {
    if (authState === null || !authState.accessToken) return;

    fetchAuthorizedAPIEndpoint(`/save_config?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractedItemName,
        excludedSpots,
        excludedPeptides,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
          Mehtod: humidityCompensationMethod,
        },
        chemicalCalibrationItemNames,
        comparisonParameters: {
          thresholdIntensity: thresholdIntensity / 100,
          thresholdSignature: thresholdSignature / 100,
          interpretation,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((saveData: any) => {
        console.log(saveData);
        setSaveData(saveData);
        setIsVisibleModal(true);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          if (resp.Reason) setError(resp.Reason);
        });
      });
  };

  return (
    <>
      <Button style={{ borderRadius: 5 }} block type="primary" onClick={() => handleSave()}>
        Save parameters
      </Button>
      <ModalComponent
        title="Save parameters"
        footer={[
          <Button type="primary" style={{ borderRadius: 5 }} onClick={() => setIsVisibleModal(false)}>
            OK
          </Button>,
        ]}
        zIndex={1000}
        open={isVisibleModal}
        onCancel={() => setIsVisibleModal(false)}
        width="50vw"
      >
        {saveData && (
          <div>
            <p>Your settings have been successfully saved. Please copy the identifier below and paste it into the protocol for the final user: </p>
            <Typography.Text style={{ fontWeight: 'bold' }} copyable>
              {saveData.ID}
            </Typography.Text>
          </div>
        )}
        {error && <p>{error}</p>}
      </ModalComponent>
    </>
  );
};

export default SaveAnalysisConfig;
