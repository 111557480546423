import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectHumidityCompensationMethod, setHumidityCompensationMethod } from '../../../features/analysisConfig/analysisConfigSlice';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';

type HumidityCompensationSettingsProps = {
  userInfo: UserClaimsWithTSDB | null;
};

const HumidityCompensationMethodSettings: React.FC<HumidityCompensationSettingsProps> = ({ userInfo }) => {
  const dispatch = useAppDispatch();
  const humidityCompensationMethod = useAppSelector(selectHumidityCompensationMethod);

  const options = [
    {
      label: 'Average ',
      value: 'average',
    },
    {
      label: 'Nearest calibrating record',
      value: 'nearest',
    },
  ];

  return (
    <div style={{ width: '100%', marginBottom: 30 }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Humidity compensation method</span>
      </div>
      <Select
        placeholder="Select humidity compensation method"
        value={humidityCompensationMethod}
        style={{ width: '100%', marginTop: 10 }}
        disabled={userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
        showSearch={true}
        allowClear={true}
        options={options}
        onChange={(value: string) => {
          if (value === undefined) {
            value = '';
          }
          dispatch(setHumidityCompensationMethod(value));
        }}
      />
    </div>
  );
};

export default HumidityCompensationMethodSettings;
