import { Alert, Col, Menu, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectCurrentRecordID,
  selectExcludedFirstCycleParamState,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationMethod,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectInterpretation,
  selectModel,
  selectRecords,
  selectSubtractItemName,
  selectThresholdIntensity,
  selectThresholdSignature,
  setAggregatePeptides,
  setBoundariesMap,
  setChemicalCalibrationItemNames,
  setExcludedFirstCycleParamState,
  setExcludedPeptides,
  setExcludedRecordIDs,
  setExcludedSpots,
  setHumidityCompensationCalibrantName,
  setHumidityCompensationMethod,
  setHumidityCompensationPositionOffset,
  setHumidityCompensationSubstractionGain,
  setInterpretation,
  setModel,
  setPCAEigenvalues,
  setPCAEigenvectors,
  setSessionID,
  setSubtractItemName,
  setThresholdIntensity,
  setThresholdSignature,
} from '../../features/analysisConfig/analysisConfigSlice';
import { AnalysisConfig, AryRecord, PeptideSet } from '../../types/analysisTypes';
import SectionPage from '../../components/section/SectionPage';
import DashboardMain from './DashboardMain';
import DashboardSelect from './dashbordRecords/DashbordSelect';
import DashboardSettings from './dashboardSettings/DashboardSettings';
import { selectVisibleSettings } from '../../features/analysisConfig/sessionInfoSlice';
import { fetchAuthorizedAPIEndpoint } from '../../utils';
import { useOktaAuth } from '@okta/okta-react';
import { resetPdfConfig } from '../../features/analysisConfig/pdfSlice';
import { LoadingOutlined } from '@ant-design/icons';
import { getPeptideSetType } from '../../compute/utils';
import { FeatureFlag, UserClaimsWithTSDB } from '../../types/userType';
import { SessionType } from '../../types/sessionType';
import DashboardMainQualityControl from './DashboardMainQualityControl';
import { RunMetadata } from '../../types/runsType';

const Stopwatch = () => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    var interval: NodeJS.Timeout | undefined = undefined;
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 100);
    }, 100);
    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  const minutes = Math.floor((time / 60000) % 60);
  const seconds = Math.floor((time / 1000) % 60);
  const milliseconds = (time / 10) % 100;

  if (minutes >= 1 || seconds >= 5) {
    return (
      <div className="stopwatch" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Loading takes more time than usual. Please wait..</span>
        <div className="numbers">
          {minutes >= 1 && <span>{minutes.toFixed(0).padStart(2)}:</span>}
          <span>{seconds.toFixed(0).padStart(2, '0')}:</span>
          <span>{milliseconds.toFixed(0).padStart(2, '0')}</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const DashboardPage: FC = () => {
  const { sessionID } = useParams<{ sessionID: string }>();

  const [analysisConfig, setAnalysisConfig] = useState<AnalysisConfig>();
  const [isExpendedMenu, setIsExpandedMenu] = useState<boolean>(true);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const records = useAppSelector(selectRecords);
  const currentRecordID = useAppSelector(selectCurrentRecordID);

  const [peptidesSetType, setPeptidesSetType] = useState<PeptideSet>(PeptideSet.Unknown);

  const visibleSettings = useAppSelector(selectVisibleSettings);
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const [sessionType, setSessionType] = useState<SessionType>(SessionType.Analysis);

  const subtractedItemName = useAppSelector(selectSubtractItemName);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const model = useAppSelector(selectModel);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);
  const humidityCompensationMethod = useAppSelector(selectHumidityCompensationMethod);
  const thresholdIntensity = useAppSelector(selectThresholdIntensity);
  const thresholdSignature = useAppSelector(selectThresholdSignature);
  const interpretation = useAppSelector(selectInterpretation);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);
  const excludedFirstCycleParamState = useAppSelector(selectExcludedFirstCycleParamState);
  const [activeComparisonSession, setActiveComparisonSession] = useState<boolean>(true);

  const { authState } = useOktaAuth();

  const dispatch = useAppDispatch();

  // set analysis config
  useEffect(() => {
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/load_config?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            resp.json().then((e: { Reason: string }) => {
              setError(`${resp.status} ${resp.statusText}: ${e.Reason}`);
            });
          }
        })
        .then((ac: AnalysisConfig) => {
          if (!ac) {
            return;
          }
          console.log('ac', ac);
          setAnalysisConfig(ac);
          if (ac.AggregatePeptides !== null) {
            dispatch(setAggregatePeptides(ac.AggregatePeptides));
          }
          if (ac.SubtractItemName !== null) {
            dispatch(setSubtractItemName(ac.SubtractItemName));
          }
          if (ac.PCAEigenvalues !== null) {
            dispatch(setPCAEigenvalues(ac.PCAEigenvalues));
          }
          if (ac.PCAEigenvectors !== null) {
            dispatch(setPCAEigenvectors(ac.PCAEigenvectors));
          }
          if (ac.ExcludedRecordIDs !== null) {
            dispatch(setExcludedRecordIDs(ac.ExcludedRecordIDs));
          }
          if (ac.BoundariesMap !== null) {
            dispatch(setBoundariesMap(ac.BoundariesMap));
          }
          if (ac.SessionID !== null) {
            dispatch(setSessionID(ac.SessionID));
          }
          if (ac.ExcludedPeptides !== null) {
            dispatch(setExcludedPeptides(ac.ExcludedPeptides));
          }
          if (ac.ExcludedSpots !== null) {
            dispatch(setExcludedSpots(ac.ExcludedSpots));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.CalibrantName) {
            dispatch(setHumidityCompensationCalibrantName(ac.HumidityCompensation.CalibrantName));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.PositionOffset) {
            dispatch(setHumidityCompensationPositionOffset(ac.HumidityCompensation.PositionOffset));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.SubstractionGain) {
            dispatch(setHumidityCompensationSubstractionGain(ac.HumidityCompensation.SubstractionGain));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.Method) {
            dispatch(setHumidityCompensationMethod(ac.HumidityCompensation.Method));
          }
          if (ac.ChemicalCalibrationItemNames !== undefined && ac.ChemicalCalibrationItemNames !== null) {
            dispatch(setChemicalCalibrationItemNames(ac.ChemicalCalibrationItemNames));
          }
          if (ac.ComparisonParameters && ac.ComparisonParameters.ThresholdSignature) {
            dispatch(setThresholdSignature(ac.ComparisonParameters.ThresholdSignature * 100));
          }
          if (ac.ComparisonParameters && ac.ComparisonParameters.ThresholdIntensity) {
            dispatch(setThresholdIntensity(ac.ComparisonParameters.ThresholdIntensity * 100));
          }
          if (ac.ComparisonParameters && ac.ComparisonParameters.Interpretation) {
            dispatch(setInterpretation(ac.ComparisonParameters.Interpretation));
          }
          if (ac.AggregatePeptides !== null) {
            dispatch(setExcludedFirstCycleParamState(ac.ExcludedFirstCycleParamState));
          }
          if (ac.Model !== undefined) {
            dispatch(setModel(ac.Model));
          }
          setIsLoading(false);
        })
        .catch((e: Error) => {
          setError(e.message);
        });

      dispatch(resetPdfConfig());
    }
  }, [sessionID]);

  // set user info
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)) {
      setSessionType(SessionType.QualityControl);
    }
  }, [userInfo]);

  // Load runs
  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/runs?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((runs: RunMetadata[]) => {
          if (runs != null) {
            setActiveComparisonSession(
              runs
                .map((run) => run.Tags)
                .flat()
                .includes('$comparison-analysis')
            );
          }
        });
    }
  }, [authState, sessionID]);

  // set PeptidesSetType
  useEffect(() => {
    if (records && currentRecordID !== undefined) {
      const record = records?.find((record: AryRecord) => record.ID === currentRecordID);

      if (record) {
        const spotgrid = [...record.Sensors.map((s) => parseInt(s))]; // parseInt tranform peptides with coordonate to number ex: 134[A3] = 134
        setPeptidesSetType(getPeptideSetType(spotgrid, true));
      } else setPeptidesSetType(PeptideSet.Unknown);
    }
  }, [currentRecordID, records]);

  // exlude peptide and spots 1 by default
  useEffect(() => {
    if (!records || !userInfo) return;
    const record = records?.find((record: AryRecord) => record.ID === currentRecordID);
    if (!record) return;

    if (peptidesSetType === PeptideSet.POR1 || peptidesSetType === PeptideSet.POR2) {
      if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) {
        dispatch(setExcludedSpots(Array.from(new Set([...excludedSpots, ...record.Sensors.filter((s) => parseInt(s) === 1).map((s) => s.toString().split('[')[1].split(']')[0])]))));
      } else {
        dispatch(setExcludedPeptides(Array.from(new Set([...excludedPeptides, '1']))));
      }
    }
  }, [currentRecordID, dispatch, peptidesSetType, records, userInfo]);

  // retrocompatibility of excludepeptides with old session
  // TODO: obselete code when all sessions used excluded peptides with non-agreggate right will be reload for update
  useEffect(() => {
    if (!records || !userInfo) return;
    const record = records?.find((record: AryRecord) => record.ID === currentRecordID);
    if (!record) return;

    if (userInfo && userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) {
      if (excludedPeptides && excludedPeptides.length !== 0) {
        const matchingSpot = excludedPeptides
          .map((peptide) => record.Sensors.filter((s) => parseInt(s) === Number(peptide)))
          .flat()
          .map((s) => s.toString().split('[')[1].split(']')[0]);

        dispatch(setExcludedSpots(Array.from(new Set([...excludedSpots, ...matchingSpot]))));
        dispatch(setExcludedPeptides([]));
      }
    }
  }, [currentRecordID, dispatch, userInfo, records]);

  useEffect(() => {
    if (visibleSettings === true) setIsExpandedMenu(false);
    else setIsExpandedMenu(true);
  }, [visibleSettings]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) return;
    if (Object.keys(boundariesMap).length === 0) return;

    fetchAuthorizedAPIEndpoint(`/update_config?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractedItemName,
        excludedSpots,
        excludedPeptides,
        excludedFirstCycleParamState,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
          method: humidityCompensationMethod,
        },

        chemicalCalibrationItemNames,
        comparisonParameters: {
          thresholdIntensity: thresholdIntensity / 100,
          thresholdSignature: thresholdSignature / 100,
          interpretation,
        },
        model,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((test: any) => {
        console.log('return update config');
        console.log(test);
      });
  }, [
    aggregatePeptides,
    authState,
    boundariesMap,
    chemicalCalibrationItemNames,
    excludedPeptides,
    excludedRecordIDs,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    humidityCompensationMethod,
    sessionID,
    subtractedItemName,
    thresholdIntensity,
    thresholdSignature,
    interpretation,
    excludedFirstCycleParamState,
    model,
  ]);

  if (error !== '') {
    return (
      <>
        <Row justify="center" style={{ marginTop: '10vh' }}>
          <Col>
            <Alert showIcon={true} style={{ textAlign: 'left' }} type="error" message={error}></Alert>
          </Col>
        </Row>
      </>
    );
  }

  if (isLoading) {
    return (
      <SectionPage boxShadow={false} height={`calc(100vh - 120px)`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <LoadingOutlined />
          <p>Please waiting, we load your session.</p>
          <Stopwatch />
        </div>
      </SectionPage>
    );
  }

  if (analysisConfig === undefined) {
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <SectionPage backgroundColor="transparent" boxShadow={false} width={visibleSettings ? 'calc(100% - 400px)' : '100%'} paddingTop={0}>
        <Row justify="center" style={{ marginBottom: 10 }}>
          {activeComparisonSession && (
            <Menu defaultSelectedKeys={[sessionType]} mode="horizontal" style={{ backgroundColor: 'transparent' }}>
              {userInfo && !userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl) && (
                <Menu.Item key={SessionType.Analysis} onClick={() => setSessionType(SessionType.Analysis)}>
                  {SessionType.Analysis}
                </Menu.Item>
              )}

              <Menu.Item key={SessionType.QualityControl} onClick={() => setSessionType(SessionType.QualityControl)}>
                {SessionType.QualityControl}
              </Menu.Item>
            </Menu>
          )}
        </Row>
        <Row gutter={[20, 20]} id="dashbord">
          <DashboardSelect isExpendedMenu={isExpendedMenu} setIsExpandedMenu={setIsExpandedMenu} sessionType={sessionType} />
          {sessionType === SessionType.Analysis ? <DashboardMain isExpendedMenu={isExpendedMenu} /> : <DashboardMainQualityControl isExpendedMenu={isExpendedMenu} />}
        </Row>
      </SectionPage>
      <DashboardSettings />
    </div>
  );
};
