import { useOktaAuth } from '@okta/okta-react';
import { Alert, Slider } from 'antd';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import tinycolor from 'tinycolor2';
import { useAppSelector } from '../../../app/hooks';
import { defaultColorPalette } from '../../../compute/colormap';
import { closeArray, defaultPlotlyArguments, getRecordNameWithBrB, getRecordNameShort } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectColormap,
  selectCurrentItemName,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationMethod,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectRecords,
  selectSessionID,
  selectSubtractItemName,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, NormType, Signature, ItemSignaturesFigureType } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint } from '../../../utils';

export const ItemSignaturesFigure: React.FC<{ figureType: ItemSignaturesFigureType; normType: NormType; heatmapRangeValue: number[]; setHeatmapRangeValue: React.Dispatch<React.SetStateAction<number[]>> }> = (props) => {
  const { figureType, normType, heatmapRangeValue, setHeatmapRangeValue } = props;

  const sessionID = useAppSelector(selectSessionID);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const records = useAppSelector(selectRecords);
  const cmap = useAppSelector(selectColormap);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const currentItemName = useAppSelector(selectCurrentItemName);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);
  const humidityCompensationMethod = useAppSelector(selectHumidityCompensationMethod);

  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  const { authState } = useOktaAuth();

  const [signatures, setSignatures] = useState<Signature[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!records) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compute/signatures?session_id=${sessionID}&item_name=${currentItemName}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractItemName,
        excludedPeptides,
        excludedSpots,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
          Method: humidityCompensationMethod,
        },
        chemicalCalibrationItemNames,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((signatures: Signature[]) => {
        for (let i = 0; i < signatures.length; i++) {
          const sig = signatures[i];
          if (!sig.NormalizedSignature) {
            setError('null signature received');
            return;
          }
        }
        setError('');
        setSignatures(signatures);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [
    sessionID,
    boundariesMap,
    excludedRecordIDs,
    aggregatePeptides,
    subtractItemName,
    excludedSpots,
    excludedPeptides,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    humidityCompensationMethod,
    chemicalCalibrationItemNames,
  ]);

  if (error !== '') {
    return (
      <div>
        <Alert type="error" message={error} />
      </div>
    );
  }

  if (signatures === undefined || signatures === null || signatures.length === 0 || cmap === undefined || records === undefined) {
    return null;
  }

  if (currentItemName === humidityCalibrationCalibrantName) {
    return (
      <Alert
        style={{ marginTop: '10px' }}
        type="info"
        message={
          <>
            This item is used as <b>humidity calibrant</b> and its signature is not available
          </>
        }
      ></Alert>
    );
  }

  var nRecordsOfCurrentItemName: number = 0;
  records.forEach((r) => {
    if (r.ItemName === currentItemName) {
      nRecordsOfCurrentItemName++;
    }
  });

  var plotlyLayout: Partial<Plotly.Layout> = { ...defaultPlotlyArguments.layout };

  var plotlyData: Plotly.Data[] = [];
  var globalMin: number = 1e6;
  var globalMax: number = -1e6;
  switch (figureType) {
    case +ItemSignaturesFigureType.Radar:
      var uniqueItemNames: Set<string> = new Set();

      var j: number = 0;
      records.forEach((record) => {
        if (record.ItemName !== currentItemName) {
          return;
        }
        var signature: Signature | undefined = undefined;
        for (let i = 0; i < signatures.length; i++) {
          if (record.ID === signatures[i].RecordID) {
            signature = signatures[i];
            break;
          }
        }

        j++;

        if (signature === undefined) {
          return;
        }

        const color = tinycolor(defaultColorPalette[cmap[currentItemName]]);
        const p = j / nRecordsOfCurrentItemName;
        const k = 20;
        if (p < 0.5) {
          color.darken(k * (1 - p));
        } else {
          color.brighten(k * p);
        }
        color.setAlpha(0.85);

        let label = getRecordNameShort(record);
        var signatureVector: number[] = signature.RawSignature;
        switch (normType) {
          case +NormType.Normalized:
            signatureVector = signature.NormalizedSignature.map((e) => 100 * e);
            break;
        }

        globalMin = Math.min(globalMin, Math.min(...signatureVector));
        globalMax = Math.max(globalMax, Math.max(...signatureVector));

        plotlyData.push({
          type: 'scatterpolar',
          hoverlabel: { namelength: -1 },
          name: record.ItemName,
          theta: closeArray(signature.Peptides.map((p) => '&nbsp;' + p)),
          r: closeArray(signatureVector),
          hoverinfo: 'y+x',
          text: label,
          hoveron: 'points',
          legendgroup: record.ItemName,
          showlegend: !uniqueItemNames.has(record.ItemName),
          line: {
            color: color.toPercentageRgbString(),
          },
        });

        uniqueItemNames.add(record.ItemName);
      });

      if (normType === +NormType.Normalized) {
        const range = [globalMin, globalMax];
        if (plotlyLayout.polar !== undefined) {
          if (plotlyLayout.polar.radialaxis !== undefined) {
            plotlyLayout.polar.radialaxis.range = range;
          } else {
            plotlyLayout.polar.radialaxis = {
              range: range,
            };
          }
        } else {
          plotlyLayout.polar = {
            radialaxis: {
              range: range,
            },
          };
        }
      } else {
        plotlyLayout.polar = { radialaxis: { fixedrange: true } };
      }
      break;
    case +ItemSignaturesFigureType.Heatmap:
      var signaturesArray: number[][] = [];
      var labels: string[] = [];
      var colors: string[] = [];
      var peptides: string[] = [];

      var ticktextY: string[] = [];
      var tickvalsY: number[] = [];

      var ticktextX: string[] = [];
      var tickvalsX: number[] = [];

      var _min = 1e6;
      var _max = -1e6;

      var signaturesWithRecords: { signature: Signature; record: AryRecord }[] = [];

      signatures.forEach((signature) => {
        var record: AryRecord | undefined = undefined;
        for (let i = 0; i < records.length; i++) {
          if (records[i].ID === signature.RecordID) {
            record = records[i];
            break;
          }
        }
        if (record === undefined) {
          return;
        }
        signaturesWithRecords.push({ signature, record });
      });

      signaturesWithRecords.sort((a, b) => {
        let sorterA = `${a.record.ItemName} #${a.record.CycleNumber.toString().padStart(3)}`;
        let sorterB = `${b.record.ItemName} #${b.record.CycleNumber.toString().padStart(3)}`;
        return sorterA < sorterB ? -1 : 1;
      });
      signaturesWithRecords.map(({ signature, record }, i) => {
        let label = getRecordNameWithBrB(record);
        var signatureVector: number[] = signature.RawSignature;
        switch (normType) {
          case +NormType.Normalized:
            signatureVector = signature.NormalizedSignature;
            break;
        }
        if (peptides.length === 0) {
          peptides = signature.Peptides.map((p) => '&nbsp;' + p);
          peptides.forEach((p, i) => {
            let parts = p.split('[');
            if (parts.length === 2) {
              let peptideCode = parts[0];
              if (ticktextX.length === 0 || peptideCode !== ticktextX[ticktextX.length - 1]) {
                ticktextX.push(peptideCode);
                tickvalsX.push(i);
              }
            } else {
              ticktextX.push(p);
              tickvalsX.push(i);
            }
          });
          if (ticktextX.length === 0 || record.ItemName !== ticktextX[ticktextX.length - 1]) {
          }
        }
        if (ticktextY.length === 0 || record.ItemName !== ticktextY[ticktextY.length - 1]) {
          ticktextY.push(record.ItemName);
          tickvalsY.push(i);
        }
        colors.push(cmap[record.ItemName]);
        labels.push(label);
        signaturesArray.push(signatureVector);
        _min = Math.min(_min, ...signatureVector);
        _max = Math.max(_max, ...signatureVector);
      });
      const _range = _max - _min;
      const zmin = _min + heatmapRangeValue[0] * _range;
      const zmax = _max - (1 - heatmapRangeValue[1]) * _range;
      plotlyData.push({
        type: 'heatmap',
        x: peptides,
        y: labels,
        z: signaturesArray,
        hoverinfo: 'y+x+z',
        hoveron: 'points',
        showscale: false,
        xgap: 0.5,
        ygap: 0.5,
        zmin: zmin,
        zmax: zmax,
        colorscale: 'Viridis', //as Plotly.ColorScale
      });
      plotlyLayout.yaxis = {
        tickmode: 'array',
        ticktext: ticktextY,
        tickvals: tickvalsY,
        automargin: true,
      };
      plotlyLayout.xaxis = {
        tickmode: 'array',
        ticktext: ticktextX,
        tickvals: tickvalsX,
        automargin: true,
      };
      break;
  }

  const plotlyConfig = { ...defaultPlotlyArguments.config };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', flexDirection: 'row' }}>
      <Plot divId="signatures_scatterpolar_plot" debug={true} data={plotlyData} useResizeHandler={true} layout={plotlyLayout} config={plotlyConfig} style={defaultPlotlyArguments.style} />
      {figureType === +ItemSignaturesFigureType.Heatmap && (
        <Slider
          style={{ height: '70%' }}
          min={0}
          max={1}
          range
          value={heatmapRangeValue}
          step={0.01}
          vertical
          onChange={(value) => {
            setHeatmapRangeValue(value);
          }}
        />
      )}
    </div>
  );
};
