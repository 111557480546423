import { Alert, Col, Popover, Row, Skeleton, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import CardSection from '../../components/section/CardSection';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectCurrentItemName,
  selectCurrentRecordID,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationMethod,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectInterpretation,
  selectModel,
  selectRecords,
  selectSensogramRenderType,
  selectSubtractItemName,
  selectThresholdIntensity,
  selectThresholdSignature,
} from '../../features/analysisConfig/analysisConfigSlice';
import { selectVisibleSettings } from '../../features/analysisConfig/sessionInfoSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultColorPalette } from '../../compute/colormap';
import { useEffect, useState } from 'react';
import { AryRecord, ComparisonResult, ComparisonResults, DisplayResult, InterpretationDecisionType, ObjectiveDecisionType, SensogramRenderType } from '../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint } from '../../utils';
import { useOktaAuth } from '@okta/okta-react';
import { MultiSignaturesWidget } from '../../components/graphic/signature/MultiSignaturesWidget';
import { IntensityPanelQualityControl } from '../../components/graphic/intensity/IntensityQualityControl';
import { MultiSensogramQualityControl } from '../../components/graphic/sensogram/MultiSensogramQualityControl';
import { getRecordNameShort } from '../../compute/utils';

type DashboardMainQualityControlProps = {
  isExpendedMenu: boolean;
};

const DashboardMainQualityControl: React.FC<DashboardMainQualityControlProps> = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(true);

  const { sessionID } = useParams<{ sessionID: string }>();
  const [displayResult, setDisplayResult] = useState<DisplayResult>();

  const [results, setResults] = useState<ComparisonResults | null>();
  const [result, setResult] = useState<ComparisonResult>();

  const subtractItemName = useAppSelector(selectSubtractItemName);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);
  const humidityCompensationMethod = useAppSelector(selectHumidityCompensationMethod);
  const thresholdIntensity = useAppSelector(selectThresholdIntensity);
  const thresholdSignature = useAppSelector(selectThresholdSignature);
  const interpretation = useAppSelector(selectInterpretation);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);
  const model = useAppSelector(selectModel);
  const currentItemName = useAppSelector(selectCurrentItemName);
  const sensogramRenderType = useAppSelector(selectSensogramRenderType);
  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const [currentRecord, setCurrentRecord] = useState<AryRecord>();

  const records = useAppSelector(selectRecords);
  const visibleSettings = useAppSelector(selectVisibleSettings);
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (authState === null || !authState.accessToken || !model) return;

    fetchAuthorizedAPIEndpoint(`/compare?session_id=${sessionID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        boundariesMap,
        subtractItemName,
        excludedRecordIDs,
        excludedPeptides,
        excludedSpots,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
          Method: humidityCompensationMethod,
        },
        chemicalCalibrationItemNames,
        comparisonParameters: {
          thresholdIntensity: thresholdIntensity / 100,
          thresholdSignature: thresholdSignature / 100,
          interpretation,
        },
        model,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((data: ComparisonResults) => {
        console.log(data);
        setResults(data);
      })
      .catch((e) => {
        setResults(null);
        console.log(e);
      });
  }, [
    authState,
    sessionID,
    boundariesMap,
    aggregatePeptides,
    subtractItemName,
    excludedRecordIDs,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
    thresholdIntensity,
    thresholdSignature,
    interpretation,
    model,
  ]);

  useEffect(() => {
    if (!results) return;
    if (sensogramRenderType === SensogramRenderType.Item) return setResult(results[sensogramRenderType].find((result) => result.ID === currentItemName));
    else if (sensogramRenderType === SensogramRenderType.Record) return setResult(results[sensogramRenderType].find((result) => result.ID === currentRecordID));
  }, [currentItemName, currentRecordID, results, sensogramRenderType]);

  useEffect(() => {
    if (model && model.Label.includes('Intensity')) return setDisplayResult(DisplayResult.IntensityResult);
    else if (model && model.Label.includes('Signature')) return setDisplayResult(DisplayResult.SignatureResult);
    else return setDisplayResult(DisplayResult.CustomResult);
  }, [model]);

  useEffect(() => {
    if (currentRecordID !== -1) {
      setCurrentRecord(records?.find((r) => r.ID === currentRecordID));
    } else {
      setCurrentRecord(undefined);
    }
  }, [records, currentRecordID]);

  const textDecisionColor = (result: ComparisonResult | null | undefined) => {
    if (result?.InterpretedDecision === InterpretationDecisionType.PASS) return defaultColorPalette.green;
    else if (result?.InterpretedDecision === InterpretationDecisionType.FAIL) return defaultColorPalette.red;
    else return 'black';
  };

  const textDecision = (result: ComparisonResult | null | undefined) => {
    let textInterpretation = '';
    if (result?.InterpretedDecision === InterpretationDecisionType.NA) textInterpretation = 'Assumed ';
    if (result?.ObjectiveDecision === ObjectiveDecisionType.SIMILAR) textInterpretation += 'Similar';
    if (result?.ObjectiveDecision === ObjectiveDecisionType.DIFFERENT) textInterpretation += 'Different';
    if (result?.ObjectiveDecision === ObjectiveDecisionType.NA) textInterpretation = 'Unknown';
    return textInterpretation;
  };

  const getAlertNoCustomResult = () => {
    if (
      (currentItemName.length >= 1 && humidityCalibrationCalibrantName !== '' && currentItemName === humidityCalibrationCalibrantName) ||
      (humidityCalibrationCalibrantName !== '' && currentRecord?.ItemName && currentRecord.ItemName === humidityCalibrationCalibrantName)
    ) {
      return (
        <Alert
          style={{ marginTop: '10px' }}
          type="info"
          message={
            <>
              This item is used as <b>humidity calibrant</b> and its signature is not available.
            </>
          }
        ></Alert>
      );
    } else {
      return <Alert style={{ marginTop: '10px' }} type="info" message={<>This selected item or record is not checked so its result is not available.</>}></Alert>;
    }
  };

  return (
    <Col id="dashbord-main" style={{ marginLeft: props.isExpendedMenu ? 350 : 70, width: props.isExpendedMenu ? 'calc(100% - 350px)' : 'calc(100% - 70px)' }}>
      <CardSection scroll="hidden">
        <Row justify="end" style={{ marginBottom: 20 }}>
          <Col>
            {humidityCalibrationCalibrantName && (
              <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
                <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
              </Popover>
            )}
          </Col>
        </Row>
        <Row gutter={[20, 20]} style={{ marginBottom: 50, justifyContent: 'center' }}>
          {(displayResult === DisplayResult.IntensityResult || displayResult === DisplayResult.SignatureResult) && (
            <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
              <Typography.Title level={5} style={{ textAlign: 'center', marginBottom: 60 }}>
                {displayResult} {currentItemName !== '' && currentItemName} {currentRecord && getRecordNameShort(currentRecord)}
              </Typography.Title>

              <div style={{ textAlign: 'center' }}>
                {result?.InterpretedDecision === InterpretationDecisionType.PASS && <FontAwesomeIcon icon="check-circle" style={{ color: defaultColorPalette.green, fontSize: 50, marginBottom: 10 }} />}
                {result?.InterpretedDecision === InterpretationDecisionType.FAIL && <FontAwesomeIcon icon="times-circle" style={{ color: defaultColorPalette.red, fontSize: 50, marginBottom: 10 }} />}
                <p style={{ fontWeight: 'bold', color: textDecisionColor(result) }}>{textDecision(result)}</p>
                {result?.DecisionValue !== undefined && (
                  <Row align="middle" justify="center">
                    <span style={{ marginRight: 5 }}>
                      {displayResult === DisplayResult.IntensityResult && 'Relative Difference:'}
                      {displayResult === DisplayResult.SignatureResult && 'Distance:'}
                    </span>
                    <span>{(result.DecisionValue * 100).toFixed(2)}%</span>
                  </Row>
                )}
              </div>
            </Col>
          )}
          {displayResult === DisplayResult.CustomResult && (
            <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
              <Typography.Title level={5} style={{ textAlign: 'center', marginBottom: 60 }}>
                Model result {currentItemName !== '' && currentItemName} {currentRecord && getRecordNameShort(currentRecord)}
              </Typography.Title>
              {result ? (
                <div style={{ textAlign: 'center' }}>
                  {result?.InterpretedDecision === InterpretationDecisionType.PASS && <FontAwesomeIcon icon="check-circle" style={{ color: defaultColorPalette.green, fontSize: 50, marginBottom: 10 }} />}
                  {result?.InterpretedDecision === InterpretationDecisionType.FAIL && <FontAwesomeIcon icon="times-circle" style={{ color: defaultColorPalette.red, fontSize: 50, marginBottom: 10 }} />}
                  <p style={{ fontWeight: 'bold' }}>
                    {result.ObjectiveDecision}: {(result.DecisionValue * 100).toFixed(2)}%
                  </p>
                </div>
              ) : (
                getAlertNoCustomResult()
              )}
            </Col>
          )}
        </Row>
      </CardSection>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
        <span>Details</span>
        {isDropdownOpen ? (
          <FontAwesomeIcon icon="caret-down" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(false)} />
        ) : (
          <FontAwesomeIcon icon="caret-right" style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setIsDropdownOpen(true)} />
        )}
      </div>
      {isDropdownOpen && (
        <Row justify="space-between" gutter={[20, 10]} style={{ marginTop: '10px' }}>
          <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
            <CardSection>
              <div id="signature-panel-div" style={{ height: '50vh' }}>
                {records === undefined || records.length === 0 ? (
                  <Skeleton active />
                ) : (
                  <>
                    {model && !model.Label.includes('Signature') && !model.Label.includes('Intensity') ? (
                      <>
                        <h3 style={{ width: '100%', textAlign: 'center', marginBottom: 10 }} className="pdf-invisible">
                          Temporal Profile
                        </h3>
                        <MultiSensogramQualityControl />
                      </>
                    ) : (
                      <>
                        <h3 style={{ width: '100%', textAlign: 'center', marginBottom: 10 }} className="pdf-invisible">
                          Signatures
                        </h3>
                        <MultiSignaturesWidget qualityControlMode />
                      </>
                    )}
                  </>
                )}
              </div>
            </CardSection>
          </Col>
          <Col xl={visibleSettings ? 24 : 12} xxl={12} span={24}>
            <CardSection>
              <div id="intensity-panel-div" style={{ height: '50vh' }}>
                {records === undefined || records.length === 0 ? <Skeleton active /> : <IntensityPanelQualityControl sessionID={sessionID} />}
              </div>
            </CardSection>
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default DashboardMainQualityControl;
